<template>
  <Candidates :user="user" :avatarText="avatarText" :searchText="searchText" fetchMethod="fetchResults" header="MY_RESULTS_HEADERS" headerText="All Results" />
</template>

<script>
import { Candidates } from 'exam-service-web-lib';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ResultsPage',
  components: {
    Candidates,
  },
  computed: {
    ...mapState(['searchText']),
    ...mapState('Auth', ['user']),
    ...mapGetters('Auth', ['avatarText']),
  },
};
</script>

<style></style>
